'use client'

import '@/app/style/globals.scss'
import * as React from 'react'

interface Props {
  readonly children: React.ReactNode
}

export default (({ children }): React.ReactElement => {
  return <main className="w-full px-4 py-14">{children}</main>
}) satisfies React.FC<Props>
